import { CommitteeComponent } from '../../components/committee';

const CommitteePage = () => {
    return (
        <>
        <CommitteeComponent />
        </>
    );
}

export default CommitteePage;