import { CTFComponent } from '../../components/ctf';

const CTFPage = () => {
    return (
        <>
        <CTFComponent/>
        </>
      );
}

export default CTFPage;